<template>
	<b-modal ref="modal-cloture" hide-footer>
		<template v-slot:modal-title>
			{{ $t("action.cloturer") }}
		</template>

		<div class="form-group" >
			<label>{{ $t('tiers.date_effet') }} *</label>
			<e-datepicker v-model="form_cloture.date"></e-datepicker>
		</div>

		<b-button
			class="mt-3 btn-block rounded-pill"
			block
			@click.prevent="deleteTiersHorse"
			variant="primary"
		>
			<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
			{{ $t("action.cloturer") }}
		</b-button>
	</b-modal>
</template>

<script>
import HorseMixin from "@/mixins/Horse.js"

export default {
	mixins: [HorseMixin],
	data: () => ({
		processing: false,
		form_cloture: {
			horse_id: null,
			tiers_id: null,
			tiershorse_id: null,
			date: new Date()
		},
	}),
	methods: {
		open(horse_id, tiers_id, tiershorse_id) {
			this.form_cloture.horse_id = horse_id
			this.form_cloture.tiers_id = tiers_id
			this.form_cloture.tiershorse_id = tiershorse_id
			this.processing = false
			this.$refs['modal-cloture'].show()
		},
		async deleteTiersHorse() {
			const horse_id = this.form_cloture.horse_id
			const tiers_id = this.form_cloture.tiers_id
			const tiershorse_id = this.form_cloture.tiershorse_id
			const date = this.form_cloture.date
			this.processing = true

			// récupère les parts actuels du cheval 
            const tiers_horse = await this.loadTiersHeavyCurrentPart(horse_id)
            const tiers_horse_duplicate = tiers_horse.filter(th => th.tiershorse_id !== parseInt(tiershorse_id)).map(th => th.tiers_horse_part)

            // duplique les autres parts non cloturés à la date de la cloture
            if(tiers_horse_duplicate.length > 0) {
	            await this.duplicateTiersHorsePart(tiers_horse_duplicate, date)
            }

			this.deleteHorseTiers(horse_id, tiers_id, tiershorse_id, date)
			.then(result => {
				this.successToast('toast.info_modif_succes')
				this.$refs['modal-cloture'].hide()
				this.$emit('submit')
			})
			.catch(e => {
				if (typeof(e) === 'string') {
					this.form_message = e
					this.failureToast(e)
				}
				else {
					console.error(e)
				}
			})
			.finally(() => {
				this.processing = false
			})
		}
	}
}
</script>